/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState } from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import _uniqBy from 'lodash/uniqBy';

import Select from '../form-elements/Select';

import { getMessageTypeDescriptionsByFeed } from '../../../utils/message-type-display';
import { MessageType } from '../../../generated/global-types';
import { FeedId, useFeedId } from '../../../contexts/FeedId';
import Theme, { ThemeType } from '../../../theme';
import RouteSelector, {
  RouteSelectorProps,
} from '../form-elements/route-selector';
import BusRouteSelector from '../form-elements/route-selector/bus-route-selector';
import { TImpact, RouteMention } from '../../../types';
import Button from '../Button';
import { ReactComponent as PlusIcon } from '../../../images/plus.svg';
import CrossIcon from '../cross-icon';
import { getRouteType } from 'utils/feed-switches';

const impactLabelCss = css`
  ${Theme.typography.sizes.medium};
  font-weight: ${Theme.typography.weights.bold};
  margin-bottom: 8px;
`;

const impactSelectContainerCss = css`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MessageTypeSelector: React.FC<
  {
    id: string;
    onChange: (value: MessageType) => void;
    className?: string;
    classNamePrefix?: string;
    type?: MessageType;
    hasMultipleImpacts?: boolean;
  } & { children?: React.ReactNode }
> = ({
  id,
  onChange,
  type,
  className,
  classNamePrefix,
  hasMultipleImpacts = false,
}) => {
  const feedId = useFeedId();

  const messageTypeOptions = getMessageTypeDescriptionsByFeed(feedId);

  return (
    <div className={className}>
      <Select
        classNamePrefix={classNamePrefix}
        id={id}
        value={messageTypeOptions.find((option) => option.value === type)}
        options={messageTypeOptions}
        onChange={({ value }: { value: MessageType }) => {
          onChange(value);
        }}
        styles={{
          control: (
            provided: {},
            state: {
              menuIsOpen: boolean;
            },
          ) => {
            return {
              borderRadius: `0 ${hasMultipleImpacts ? '0' : '4px'} ${
                state.menuIsOpen || hasMultipleImpacts ? '0' : '4px'
              } 0`,
            };
          },
        }}
      />
    </div>
  );
};

const addEmptyImpact = (impacts: TImpact[] = []) => {
  return [
    ...impacts,
    {
      routes: [],
      messageType: '',
      isAgencyWide: false,
    },
  ] as TImpact[];
};

export const getAllRoutesFromImpacts = (impacts: TImpact[]) => {
  const allRoutes = impacts.reduce((acc, impact) => {
    return [...acc, ...impact.routes];
  }, [] as RouteMention[]);

  return _uniqBy(allRoutes, 'routeId');
};

export const getMessageType = (impacts: TImpact[]): MessageType | undefined => {
  const uniqueImpacts = _uniqBy(
    impacts.filter((impact) => !!impact && !!impact.messageType),
    'messageType',
  );

  let messageType;
  if (uniqueImpacts.length === 1) {
    messageType = uniqueImpacts[0].messageType;
  } else if (uniqueImpacts.length > 1) {
    messageType = MessageType.MULTIPLE_CHANGES;
  }

  return messageType;
};

export const removeImpact = (impacts: TImpact[], idx: number) => {
  const beforeEls = impacts.slice(0, idx);
  const afterEls = impacts.slice(idx + 1);
  return [...beforeEls, ...afterEls];
};

export const changeImpactMessageType = (
  impacts: TImpact[],
  messageType: MessageType,
  idx: number,
) => {
  const newImpacts = [...impacts];
  newImpacts[idx] = {
    messageType,
    routes: [...impacts[idx].routes],
    isAgencyWide: impacts[idx].isAgencyWide,
  };
  return newImpacts;
};

export const changeImpactRoutes = (
  impacts: TImpact[],
  routes: RouteMention[],
  isAgencyWide: boolean,
  idx: number,
) => {
  const newImpacts = [...impacts];
  newImpacts[idx] = {
    routes: _uniqBy(routes, 'routeId'),
    messageType: impacts[idx].messageType,
    isAgencyWide,
  };
  return newImpacts;
};

const ImpactSelector: React.FC<
  {
    id: string;
    onChange: (value: TImpact[]) => void;
    onChangeTargetedRoutes?: () => void;
    className?: string;
    value?: TImpact[];
  } & { children?: React.ReactNode }
> = ({ id, className, onChange, onChangeTargetedRoutes, value }) => {
  const feedId = useFeedId();
  const [numRemoved, setNumRemoved] = useState(0);
  const hasMultipleImpacts = value && value.length > 1;

  const impactSelectors = value?.map((impact: TImpact, idx: number) => {
    const linesId = `${id}-lines-${idx}`;
    const messageTypeId = `${id}-messageType-${idx}`;
    const key = `${linesId}-${numRemoved}`;
    const areLabelsHidden = idx > 0;

    const linesLabel = areLabelsHidden ? (
      <VisuallyHidden>
        <label css={impactLabelCss} htmlFor={linesId}>
          {getRouteType(feedId)}
        </label>
      </VisuallyHidden>
    ) : (
      <label css={impactLabelCss} htmlFor={linesId}>
        {getRouteType(feedId)}
      </label>
    );

    const messageTypeLabel = areLabelsHidden ? (
      <VisuallyHidden>
        <label css={impactLabelCss} htmlFor={messageTypeId}>
          Status
        </label>
      </VisuallyHidden>
    ) : (
      <label css={impactLabelCss} htmlFor={messageTypeId}>
        Status
      </label>
    );

    const routeSelectorProps: RouteSelectorProps = {
      css: (theme: ThemeType) => css`
        border-radius: 4px 0px 0px 4px;
        background-color: ${theme.colors.white};
      `,
      classNamePrefix: 'route-selector',
      id: linesId,
      routes: impact.routes,
      onChange: (routes: RouteMention[], isAgencyWide?: boolean) => {
        if (onChangeTargetedRoutes) {
          onChangeTargetedRoutes();
        }
        onChange(changeImpactRoutes(value, routes, isAgencyWide ?? false, idx));
      },
      isAgencyWide: impact.isAgencyWide,
      selectProps: {
        selectStyles: {
          control: (
            provided: {},
            state: {
              menuIsOpen: boolean;
            },
          ) => {
            return {
              borderRadius: `4px 0 0 ${state.menuIsOpen ? '0' : '4px'}`,
              boxShadow: `inset 0 0 4px ${Theme.colors['border-dark']}`,
            };
          },
        },
      },
      isMulti: true,
      enableQuickSelect: true,
    };

    return (
      <div
        key={key}
        className={className}
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 12px;
        `}
      >
        <div css={impactSelectContainerCss}>
          {linesLabel}
          {feedId === FeedId.NYCTBus ? (
            <BusRouteSelector {...routeSelectorProps} />
          ) : (
            <RouteSelector {...routeSelectorProps} />
          )}
        </div>
        <div css={impactSelectContainerCss}>
          {messageTypeLabel}
          <div
            css={css`
              display: flex;
              border-radius: 0px 4px 4px 0px;
              border-left: none;
              margin-right: ${hasMultipleImpacts ? '' : '48px'};
            `}
          >
            <MessageTypeSelector
              css={css`
                flex: 1;
                background-color: white;
                border-radius: ${hasMultipleImpacts
                  ? '0px'
                  : '0px 4px 4px 0px'};
              `}
              classNamePrefix="message-type-selector"
              id={messageTypeId}
              onChange={(messageType) => {
                onChange(changeImpactMessageType(value, messageType, idx));
              }}
              type={impact.messageType}
              hasMultipleImpacts={hasMultipleImpacts}
            />
            {hasMultipleImpacts && (
              <div
                css={(theme: ThemeType) => css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 48px;
                  background-color: #eaeaea;
                  border-radius: 0 4px 4px 0;
                  border-left: 1px solid ${theme.colors['border-dark']};
                `}
              >
                <button
                  css={css`
                    width: 100%;
                    height: 100%;
                  `}
                  type="button"
                  aria-label="Remove Status"
                  onClick={() => {
                    setNumRemoved((prevNumRemoved) => prevNumRemoved + 1);
                    onChange(removeImpact(value, idx));
                  }}
                >
                  <CrossIcon size="10px" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      css={css`
        margin-bottom: 12px;
      `}
      id={id}
    >
      <div>{impactSelectors}</div>
      <Button
        plain
        type="button"
        onClick={() => onChange(addEmptyImpact(value))}
      >
        <PlusIcon /> Add Status
      </Button>
    </div>
  );
};

export default ImpactSelector;
