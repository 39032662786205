/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import { getClearedPlannedWorkMessageTypeDescriptionsByFeed } from 'utils/message-type-display';
import * as commonStyles from '../../../common/styles';

import PageMeta from '../../../common/PageMeta';
import PageHeading from '../../../scaffolding/PageHeading';
import AppLink, { ApplinkStyles } from '../../../common/app-link';
import Loader from '../../../common/skeletons/PageWithTable';
import { ButtonList } from '../../../scaffolding/PageHeading.styled';
import { useFeedId } from '../../../../contexts/FeedId';

import PlannedWorkTable, {
  TPlannedWorkTableSortingTypes,
} from '../../../common/planned-work/PlannedWorkTable';
import PlannedWorkFilters from '../../../common/planned-work/PlannedWorkFilters';

import { PlannedWorkStatus } from '../../../../generated/global-types';
import NoMessages from '../../../common/NoMessages';

import {
  PLANNED_WORK_STATUSES,
  PLANNED_WORK_LIVE_OPTIONS,
} from '../../../../constants/planned-work';

import usePlannedWorkQuery from '../../../../hooks/use-planned-work-query';

const statusOptions = PLANNED_WORK_STATUSES.filter(
  (status) =>
    status.data.status === 'PUBLISHED' || status.data.status === 'LIVE',
);

const liveOptions = PLANNED_WORK_LIVE_OPTIONS;

const PlannedWork: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const feedId = useFeedId();
  const {
    query: { loading, data },
    setPage,
    setOrderBy,
    setDirection,
    pageNum,
    perPage,
    orderBy,
    direction,
    hasFilter,
  } = usePlannedWorkQuery([
    PlannedWorkStatus.LIVE,
    PlannedWorkStatus.PUBLISHED,
    PlannedWorkStatus.CLEARING,
  ]);

  const categoryOptions =
    getClearedPlannedWorkMessageTypeDescriptionsByFeed(feedId);

  const messages = data?.searchPlannedWorks?.results || [];
  const totalCount = data?.searchPlannedWorks?.totalCount ?? 0;
  return (
    <section css={commonStyles.container}>
      <PageMeta title="Planned Work" />
      <PageHeading breadcrumbs={['Messages']} title="Planned Work">
        <ButtonList>
          <AppLink
            to={`/${feedId}/planned-work/compose`}
            styleVariations={[
              ApplinkStyles.PRIMARY_BUTTON,
              ApplinkStyles.LARGE_BUTTON,
            ]}
          >
            Compose
          </AppLink>
        </ButtonList>
      </PageHeading>
      <div css={commonStyles.pageContent}>
        <div
          css={[
            commonStyles.flexCol,
            css`
              width: 100%;
            `,
          ]}
        >
          <React.Fragment>
            <PlannedWorkFilters
              feedId={feedId}
              statusOptions={statusOptions}
              categoryOptions={categoryOptions}
              liveOptions={liveOptions}
              disabled={loading}
            />
            <Loader loading={loading && !messages.length}>
              {messages.length ? (
                <PlannedWorkTable
                  onNextClick={() => setPage(`${pageNum + 1}`)}
                  onPrevClick={() => setPage(`${pageNum - 1}`)}
                  onSortClick={({ orderBy: newOrderBy, isDesc: newIsDesc }) => {
                    setOrderBy(newOrderBy);
                    setDirection(newIsDesc ? 'desc' : 'asc');
                    setPage('1');
                  }}
                  feedId={feedId}
                  page={pageNum}
                  perPage={perPage}
                  orderBy={orderBy as TPlannedWorkTableSortingTypes}
                  isDesc={direction === 'desc'}
                  messages={messages}
                  totalCount={totalCount}
                  loading={loading}
                />
              ) : (
                <div
                  css={css`
                    flex: 1 1 100%;
                    display: flex;
                  `}
                >
                  {hasFilter ? (
                    <NoMessages
                      heading="No Messages"
                      message="No messages match provided filters."
                      ctaLabel="Clear Filters"
                      ctaLink={`/${feedId}/planned-work`}
                    />
                  ) : (
                    <NoMessages
                      heading="All Clear!"
                      message="There is no active Planned Work."
                      ctaLabel="Compose Planned Work"
                      ctaLink={`/${feedId}/planned-work/compose`}
                    />
                  )}
                </div>
              )}
            </Loader>
          </React.Fragment>
        </div>
      </div>
    </section>
  );
};

export default PlannedWork;
