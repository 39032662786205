/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

import useQueryParams from '../../../hooks/useQueryParams';

import * as Layout from '../../common/layout';
import Loader from '../../common/skeletons/PageWithTable';
import Pagination from '../../common/Pagination';
import { ThemeType } from '../../../theme';
import { useRoutesByFeedId } from '../../../contexts/Routes';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as TRoute } from '../../../generated/RoutesByFeed';

import Bullet, { BulletSize } from '../../common/Bullet';
import Select from '../../common/form-elements/Select';
import RouteSelector from '../../common/form-elements/route-selector';
import BusRouteSelector from '../../common/form-elements/route-selector/bus-route-selector';

import PageMeta from '../../common/PageMeta';
import PageHeading from '../../scaffolding/PageHeading';
import { BOROUGH_LABELS } from '../../../utils/boroughs';

import { routeToRouteMention } from '../../../utils/route-mentions';
import { RouteMention } from '../../../types';

import { SortArrows } from '../../common/table-elements';

import {
  table,
  tableHeader,
  tableBody,
  tableHeaderCell,
  tableCell,
  tableRow,
} from '../../common/table-classes';

import { Borough, StopsOrderBy } from '../../../generated/global-types';
import {
  ScreensStops as ScreensStopsType,
  ScreensStopsVariables,
} from '../../../generated/ScreensStops';

import { useFeedId, FeedId } from '../../../contexts/FeedId';
import { getRouteType, isNyct } from '../../../utils/feed-switches';

import NoMessages from '../../common/NoMessages';

import {
  WEB_NO_SCREENS_MESSAGE,
  WEB_NO_MESSAGES_HEADING,
  WEB_NO_SCREENS_MESSAGE_FILTERS,
  WEB_NO_MESSAGES_HEADING_FILTERS,
} from '../../../constants/empty-states';
import BaseSearchInput from '../../common/form-elements/search-input';
import Button from '../../common/Button';
import { onError } from '../../../utils/error-handler';

const ScreensStops = loader('../../../graphql/ScreensStops.gql');

const PAGE_SIZE = 50;

const QUERY_PARAM_KEYS = {
  direction: 'dir',
  lines: 'lines',
  orderBy: 'order',
  page: 'page',
  screens: 'screens',
  search: 'search',
  stations: 'stations',
  status: 'status',
  routes: 'lines',
  stops: 'stops',
};

const BOROUGH_OPTIONS = [{ value: '', label: 'Borough(s): All' }].concat(
  Object.entries(BOROUGH_LABELS).map(([value, label]) => ({ value, label })),
);

const AVAILABLE_SCREENS_OPTIONS = [
  { value: '', label: 'Only stations with available screens' },
  { value: 'all', label: 'All stations' },
];

const setQueryParams = (
  history: any,
  params: { [key: string]: string | number },
  scrollToTop = true,
) => {
  const query = new URLSearchParams(history.location.search);
  Object.entries(params).forEach(([name, value]) => {
    query.set(name, value as string);
  });
  history.push({ ...history.location, search: query.toString() });
  if (scrollToTop) {
    window.scrollTo(0, 0);
  }
};

const Screens: React.FC<unknown & { children?: React.ReactNode }> = () => {
  const feedId = useFeedId();
  const queryParams = useQueryParams();
  const orderBy = queryParams.get('orderBy') || 'NAME_ASC';
  const pageParam = queryParams.get('page') || '';
  const borough = queryParams.get('borough') || '';
  const stopId = queryParams.get('stopId') || '';
  const availability = queryParams.get('availability') || '';
  const page = Number.isNaN(parseInt(pageParam, 10))
    ? 1
    : parseInt(pageParam, 10);
  const offset = (page - 1) * PAGE_SIZE;
  const search = queryParams.get(QUERY_PARAM_KEYS.search) || '';
  const history = useHistory();

  const routes: string[] = decodeURIComponent(
    queryParams.get(QUERY_PARAM_KEYS.routes) || '',
  )
    .split(',')
    .filter(Boolean);
  const allRoutes = useRoutesByFeedId(feedId);
  const routeMentions: RouteMention[] = (
    routes
      .map((gtfsId: string) => allRoutes.find((r) => r.gtfsId === gtfsId))
      .filter(Boolean) as TRoute[]
  ).map((r) => routeToRouteMention({ route: r }));

  const variables = {
    feedId: feedId || '',
    query: search || null,
    borough: (borough || null) as Borough,
    routeIds: routes.length ? routes : null,
    stopId: stopId || null,
    hasScreensWithPartnerSlots: availability !== 'all',
    orderBy: [orderBy as StopsOrderBy],
    first: PAGE_SIZE,
    offset,
  };
  const { loading, data = { stops: { nodes: [], totalCount: 0 } } } = useQuery<
    ScreensStopsType,
    ScreensStopsVariables
  >(ScreensStops, {
    variables,
    onError,
  });

  const filtersApplied =
    search || borough || routes.length || stopId || availability;

  const routeSelectorProps = {
    css: (theme: ThemeType) => css`
      flex: 1;
      margin-right: ${theme.spacing.xsmall};
      background-color: white;
    `,
    isMulti: true,
    hasControlBoxShadow: true,
    placeholder: 'Line(s)',
    onChange: (routes: RouteMention[]) => {
      setQueryParams(history, {
        [QUERY_PARAM_KEYS.routes]: routes.map((r) => r.routeId).toString(),
        page: 1,
        [QUERY_PARAM_KEYS.stops]: '',
      });
    },
    routes: routeMentions,
  };

  return (
    <Layout.Page>
      <PageMeta title="Screens" />
      <PageHeading title="By Station" breadcrumbs={['Screens']} />
      {loading && !data ? (
        <div
          css={(theme) => css`
            padding: ${theme.spacing.small} ${theme.spacing.large};
          `}
        >
          <Loader loading={loading && !data} />
        </div>
      ) : (
        <React.Fragment>
          <Layout.ContentWrapper
            css={(theme: ThemeType) => css`
              margin: ${theme.spacing.small} 0 0;
            `}
          >
            <form>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    height: 100%;
                    padding-bottom: 12px;
                    &:first-of-type > *:first-of-type {
                      width: 100%;
                    }
                  `}
                >
                  <BaseSearchInput
                    type="text"
                    placeholder="Search stations"
                    value={search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setQueryParams(history, {
                        [QUERY_PARAM_KEYS.search]: e.target.value,
                        page: 1,
                      })
                    }
                    onClear={() => {
                      setQueryParams(history, {
                        [QUERY_PARAM_KEYS.search]: '',
                        page: 1,
                      });
                    }}
                  />
                </div>
                {isNyct(feedId) && (
                  <Select
                    css={(theme: ThemeType) => css`
                      flex: 1;
                      margin-right: ${theme.spacing.xsmall};
                      background-color: white;
                    `}
                    onChange={({ value }: { value: string }) => {
                      setQueryParams(history, { borough: value, page: 1 });
                    }}
                    options={BOROUGH_OPTIONS}
                    value={
                      BOROUGH_OPTIONS.find(({ value }) => value === borough) ||
                      ''
                    }
                    hasControlBoxShadow
                  />
                )}
                {feedId === FeedId.NYCTBus ? (
                  <BusRouteSelector {...routeSelectorProps} />
                ) : (
                  <RouteSelector {...routeSelectorProps} />
                )}

                <Select
                  css={css`
                    flex: 1;
                    background-color: white;
                  `}
                  onChange={({ value }: { value: string }) => {
                    setQueryParams(history, { availability: value, page: 1 });
                  }}
                  options={AVAILABLE_SCREENS_OPTIONS}
                  value={AVAILABLE_SCREENS_OPTIONS.find(
                    ({ value }) => value === availability,
                  )}
                  hasControlBoxShadow
                />
                <Button
                  size="small"
                  css={css`
                    margin-left: 12px;
                    padding: 0 12px;
                    min-width: 64px;
                  `}
                  aria-label="Clear Filters"
                  type="button"
                  disabled={!filtersApplied}
                  onClick={() => {
                    setQueryParams(history, {
                      page: 1,
                      borough: '',
                      stopId: '',
                      [QUERY_PARAM_KEYS.search]: '',
                      [QUERY_PARAM_KEYS.routes]: '',
                      availability: '',
                    });
                  }}
                >
                  Clear
                </Button>
              </div>
            </form>
          </Layout.ContentWrapper>
          <div
            css={(theme: ThemeType) => css`
              margin: ${theme.spacing.small} ${theme.spacing.large};
            `}
          >
            {data?.stops?.nodes.length ? (
              <React.Fragment>
                <div css={table}>
                  <div css={tableHeader}>
                    {isNyct(feedId) && (
                      <span
                        css={css`
                          ${tableHeaderCell};
                          width: 135px;
                        `}
                      >
                        Borough
                      </span>
                    )}
                    <div css={tableHeaderCell}>
                      <button
                        type="button"
                        css={css`
                          color: inherit;
                          font: inherit;
                        `}
                        onClick={() =>
                          setQueryParams(history, {
                            orderBy:
                              orderBy === 'NAME_DESC'
                                ? 'NAME_ASC'
                                : 'NAME_DESC',
                            page: 1,
                          })
                        }
                      >
                        Station{' '}
                        <SortArrows
                          isSorted={['NAME_ASC', 'NAME_DESC'].includes(orderBy)}
                          isSortedDesc={orderBy === 'NAME_DESC'}
                        />
                      </button>
                    </div>
                    <div
                      css={css`
                        ${tableHeaderCell};
                        max-width: 380px;
                      `}
                    >
                      <button
                        type="button"
                        css={css`
                          color: inherit;
                          font: inherit;
                        `}
                        onClick={() =>
                          setQueryParams(history, {
                            orderBy:
                              orderBy === 'GTFS_ID_DESC'
                                ? 'GTFS_ID_ASC'
                                : 'GTFS_ID_DESC',
                            page: 1,
                          })
                        }
                      >
                        Line(s){' '}
                        <SortArrows
                          isSorted={['GTFS_ID_ASC', 'GTFS_ID_DESC'].includes(
                            orderBy,
                          )}
                          isSortedDesc={orderBy === 'GTFS_ID_DESC'}
                        />
                      </button>
                    </div>
                  </div>
                  <div css={tableBody}>
                    {data.stops.nodes.map((stop) => {
                      return (
                        <Link
                          to={`/${feedId}/stops/${stop.gtfsId}/screens`}
                          key={stop.gtfsId}
                          css={css`
                            ${tableRow};
                            color: inherit;
                            margin: 0;
                          `}
                        >
                          {isNyct(feedId) && (
                            <span
                              css={css`
                                ${tableCell};
                                text-align: center;
                                font-weight: bold;
                              `}
                            >
                              {stop.boroughs && stop.boroughs[0]
                                ? stop.boroughs[0].substr(0, 1) +
                                  stop.boroughs[0].substr(1).toLowerCase()
                                : ''}
                            </span>
                          )}
                          <span css={tableCell}>{stop.name}</span>
                          <span css={tableCell}>
                            <span
                              css={css`
                                display: flex;
                                justify-content: space-between;
                              `}
                            >
                              <span
                                css={css`
                                  display: block;

                                  * {
                                    margin-right: 1px;
                                  }

                                  [data-bullet-route-id^='mtasbwy-'] {
                                    margin-top: 4px;
                                  }
                                `}
                              >
                                {stop.routes.nodes.map((r) => (
                                  <Bullet
                                    routeId={r.gtfsId}
                                    size={BulletSize.xsmall}
                                    key={r.gtfsId}
                                  />
                                ))}
                              </span>
                              <span>{stop.screens.totalCount} screens</span>
                            </span>
                          </span>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div
                  css={css`
                    margin-top: 1rem;
                  `}
                >
                  <Pagination
                    currentPage={page}
                    itemsPerPage={PAGE_SIZE}
                    totalItems={data && data.stops ? data.stops.totalCount : 0}
                    onPrevClick={() =>
                      setQueryParams(history, { page: page - 1 })
                    }
                    onNextClick={() =>
                      setQueryParams(history, { page: page + 1 })
                    }
                  />
                </div>
              </React.Fragment>
            ) : (
              <NoMessages
                css={css`
                  margin-top: 45px;
                `}
                icon={!!filtersApplied}
                heading={
                  filtersApplied
                    ? WEB_NO_MESSAGES_HEADING_FILTERS
                    : WEB_NO_MESSAGES_HEADING
                }
                message={
                  filtersApplied
                    ? WEB_NO_SCREENS_MESSAGE_FILTERS
                    : WEB_NO_SCREENS_MESSAGE
                }
              />
            )}
          </div>
        </React.Fragment>
      )}
    </Layout.Page>
  );
};

export default Screens;
