/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as Sentry from '@sentry/browser';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';

import { useFeatureFlag, isFeatureEnabled } from 'hooks/useFeatureFlag';

import { FeatureFlagName, TargetType } from '../../../generated/global-types';

import { useCurrentUser } from 'contexts/CurrentUser';
import { FeedId } from 'types/feeds';

import Loader from '../../common/skeletons/PageWithContent';
import { ThemeType } from '../../../theme';
import Button from '../../common/Button';

import PageMeta from '../../common/PageMeta';
import Bullet, { BulletSize } from '../../common/Bullet';
import { BOROUGH_LABELS } from '../../../utils/boroughs';
import AppLink from '../../common/app-link';

import HR from '../../common/HR';
import BackOnlyPageHeading from '../../scaffolding/back-only-page.heading';

import ScreenLocking from './ScreenLocking';
import LockedContent from './LockedContent';
import SingleScreenCampaign from './single-screen-campaign';
import TagsModal from './tags-modal';

import {
  UpdateScreenNotes,
  UpdateScreenNotesVariables,
} from '../../../generated/UpdateScreenNotes';

import getScreenIcon from '../../../utils/get-screen-icon';

import {
  Screen as ScreenType,
  ScreenVariables,
  Screen_screen2_Screens2 as Screens2,
} from '../../../generated/Screen';

import { SCREEN_STATIC_NOTICE } from '../../../constants/alerts';

import StatusBanner from '../../common/status-banner';
import * as S from './index.styled';
import {
  useNext24hrs,
  ScreenPageHeader,
  BlueprintButton,
  InternalNotesEditor,
  NoteSaveState,
  PageWrapper,
  ContentWrapper,
} from './shared';
import { getDimensionsFromTargetType } from './in-screens-preview/helpers';
import OrderedScreenContent, { getSetIndex } from './OrderedScreenContent';

const ScreenQuery = loader('../../../graphql/Screen.gql');
const UpdateScreenNotesMutation = loader(
  '../../../graphql/UpdateScreenNotes.gql',
);

const OutfrontScreen: React.FC = () => {
  const { feedId, id: assetId } = useParams<{ feedId: FeedId; id: string }>();
  const { start, end } = useNext24hrs();
  const currentUser = useCurrentUser();

  const plannedWorkEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.PLANNED_WORK,
          feedId as FeedId,
        )
      : false;
  const alertsEnabled =
    currentUser && feedId
      ? isFeatureEnabled(
          currentUser,
          FeatureFlagName.SERVICE_ALERTS,
          feedId as FeedId,
        ) &&
        isFeatureEnabled(
          currentUser,
          FeatureFlagName.SERVICE_ALERT_SCREEN_TARGETS,
          feedId as FeedId,
        )
      : false;

  const { loading, error, data, refetch } = useQuery<
    ScreenType,
    ScreenVariables
  >(ScreenQuery, {
    variables: {
      timeframe: {
        start: { value: start, inclusive: true },
        end: { value: end, inclusive: true },
      },
      assetId: assetId || '',
      plannedWorkEnabled,
      alertsEnabled,
    },
  });

  const [updateScreenNotes] = useMutation<
    UpdateScreenNotes,
    UpdateScreenNotesVariables
  >(UpdateScreenNotesMutation);

  const [noteValue, setNoteValue] = useState<string>('');
  const [noteSaveState, setNoteSaveState] = useState<NoteSaveState>(
    NoteSaveState.none,
  );

  const [tagsModalOpen, setTagsModalOpen] = useState(false);

  const screenLockingEnabled = useFeatureFlag(
    FeatureFlagName.SCREEN_LOCKING_UI,
  );
  const singleScreenCampaignsEnabled = useFeatureFlag(
    FeatureFlagName.ENABLE_SINGLE_SCREEN_CAMPAIGNS,
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNoteSaveState(NoteSaveState.saving);
    try {
      const screen = data?.screen2 ?? { name: '' };
      await updateScreenNotes({
        variables: {
          name: screen.name,
          notes: noteValue || '',
        },
      });
      setNoteSaveState(NoteSaveState.success);
    } catch (e) {
      Sentry.captureException(e);
      setNoteSaveState(NoteSaveState.error);
    } finally {
      setTimeout(() => {
        setNoteSaveState(NoteSaveState.none);
      }, 1000);
    }
  };

  if (error) {
    return <div>Error {error.toString()}</div>;
  }

  if (loading) {
    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <Loader loading={loading} />
      </div>
    );
  }

  const screen = data?.screen2;
  if (!screen || !screen.gtfsStop) {
    return <div>Error: not found</div>;
  }

  const borough = (screen.gtfsStop.boroughs || [null])[0];
  const hasStaticTag = screen.criteria.Content_type === 'Static';
  const blueprint = screen.gtfsStop.blueprintUpload?.signedS3Url || null;
  const gtfsStopId = screen.criteria.gtfs_stop_id;
  // We get the dimensions from this function because the targetable
  // section on DUP screens is different than the actual screen size
  const screenDimensions = getDimensionsFromTargetType(
    screen.targetType as TargetType,
  );

  const siblingNodes = [...(screen?.siblings?.nodes || [])];
  const siblings = siblingNodes.sort(
    (a, b) => getSetIndex(b as Screens2) - getSetIndex(a as Screens2),
  );
  const setIndex = getSetIndex(screen);

  const screenCampaignScreenContent = screen.campaignScreenContent;
  const screenPlannedWorks = plannedWorkEnabled ? screen?.plannedWorks : null;
  const screenAlerts = alertsEnabled ? screen?.alerts : null;

  const hasActiveContent =
    screenCampaignScreenContent?.nodes.length ||
    screenPlannedWorks?.totalCount ||
    screenAlerts?.totalCount;

  let position: JSX.Element | null = null;
  if (siblings.length) {
    let positionLabel: string | null = null;

    if (siblings.length > 3) {
      positionLabel = `${setIndex + 1} / ${siblings.length + 1}`;
    } else if (setIndex === 0) {
      positionLabel = 'Left';
    } else if (setIndex === 1 && siblings.length === 2) {
      positionLabel = 'Middle';
    } else {
      positionLabel = 'Right';
    }

    position = (
      <p>
        <b>Position:</b> {positionLabel}
      </p>
    );
  }

  return (
    <PageWrapper>
      <PageMeta title={screen.actualName} />
      <BackOnlyPageHeading
        back={{
          to: `/${feedId}/stops/${gtfsStopId}/screens`,
          title: 'Back to Station',
        }}
      />
      <ContentWrapper>
        <ScreenPageHeader
          name={screen.actualName}
          stopName={screen.gtfsStop.name ?? ''}
          routeIds={screen.gtfsStop.routes.nodes.map((r) => r.gtfsId)}
        />
        <HR />
        <Loader loading={loading && !data}>
          <div>
            <S.DetailsWrapper>
              <S.Details>
                <div>
                  {getScreenIcon(
                    {
                      type: screen.criteria.type.toUpperCase(),
                      setKey: screen.criteria.set_length === '0' ? null : 'key',
                      setIndex,
                      orientation: screen.orientation,
                    },
                    50,
                  )}
                </div>
                <p>
                  <b>Station:</b> {screen.gtfsStop.name}
                </p>
                <p>
                  <b>Borough:</b> {borough && BOROUGH_LABELS[borough]}
                </p>
                <p>
                  <b>Lines:</b>{' '}
                  {screen.gtfsStop.routes.nodes.map((n) => (
                    <React.Fragment key={n.gtfsId}>
                      <Bullet
                        routeId={n.gtfsId}
                        size={BulletSize.small}
                        style={{
                          marginTop: '3px',
                        }}
                      />{' '}
                    </React.Fragment>
                  ))}
                </p>
                <p>
                  <b>Player Name:</b> {screen.actualName}
                </p>
                {position}
                {siblings.length > 0 && (
                  <p
                    css={css`
                      margin-bottom: 0px;
                    `}
                  >
                    <b>Siblings:</b>{' '}
                    {siblings.map((sibling, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={index}>
                        <AppLink
                          css={(theme: ThemeType) => css`
                            color: ${theme.colors.accent1};
                            text-decoration: underline;
                          `}
                          to={`/${feedId}/screens/${sibling.id}`}
                        >
                          {sibling.name}
                        </AppLink>
                      </React.Fragment>
                    ))}
                  </p>
                )}
                {hasStaticTag && (
                  <div
                    css={css`
                      width: 312px;
                      margin-top: 16px;
                    `}
                  >
                    <StatusBanner
                      status="warning"
                      text={SCREEN_STATIC_NOTICE}
                      transition={false}
                      hasIcon
                    />
                  </div>
                )}
                {blueprint && <BlueprintButton href={blueprint} />}
                <S.TagsHolder>
                  <Button
                    css={css`
                      padding: 5px 15px;
                      height: 40px;
                    `}
                    size="medium"
                    onClick={() => setTagsModalOpen(true)}
                  >
                    View Tags
                  </Button>
                  <TagsModal
                    isOpen={tagsModalOpen}
                    screen={screen}
                    onDismiss={() => setTagsModalOpen(false)}
                    refetchScreen={refetch}
                  />
                </S.TagsHolder>
              </S.Details>
              <S.InternalNotes>
                <InternalNotesEditor
                  defaultValue={screen.notes || ''}
                  value={noteValue}
                  state={noteSaveState}
                  onChange={(text) => setNoteValue(text)}
                  onSubmit={onSubmit}
                />
              </S.InternalNotes>
            </S.DetailsWrapper>
            {screenLockingEnabled && screen.isLockable && (
              <S.ContentWrapper>
                <ScreenLocking
                  screenName={screen.name}
                  screenDimensions={{
                    width: screen?.width,
                    height: screen?.height,
                  }}
                  currentScreenLock={screen.currentScreenLock}
                  needsConfirmationBeforeUnlocking={!hasActiveContent}
                />
              </S.ContentWrapper>
            )}
            {singleScreenCampaignsEnabled && (
              <S.ContentWrapper>
                <SingleScreenCampaign
                  screen={screen}
                  disabled={!!screen.currentScreenLock}
                />
              </S.ContentWrapper>
            )}
            <S.ContentWrapper hideSeparator>
              {screen.currentScreenLock !== null ? (
                <React.Fragment>
                  <LockedContent
                    screenLock={screen?.currentScreenLock?.upload}
                    height={screenDimensions[1]}
                    width={screenDimensions[0]}
                    scale={
                      screen.targetType === TargetType.DUP ? 0.55 : undefined
                    }
                    zipContentsScale={
                      screen.targetType === TargetType.DUP ? 0.17 : undefined
                    }
                  />
                  <ScreenLocking
                    screenName={screen.name}
                    screenDimensions={{
                      width: screen?.width,
                      height: screen?.height,
                    }}
                    currentScreenLock={screen.currentScreenLock}
                    needsConfirmationBeforeUnlocking={!hasActiveContent}
                    updateContent
                  />
                </React.Fragment>
              ) : (
                <OrderedScreenContent screenWithContent={screen} />
              )}
            </S.ContentWrapper>
          </div>
        </Loader>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default OutfrontScreen;
