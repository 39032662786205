import React, { PropsWithChildren, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { sortedRoutes } from '@mta-live-media-manager/shared';
import {
  RoutesByFeed,
  RoutesByFeedVariables,
  RoutesByFeed_routes_RoutesConnection_nodes_Route as Route,
} from '../generated/RoutesByFeed';
import { FeedId } from './FeedId';
import { isOutfront } from 'utils/feed-switches';

const { REACT_APP_DEPLOY_ENV } = process.env;
const RoutesByFeedQuery = loader('../graphql/RoutesByFeed.gql');

const FeedIdContext = React.createContext<Route[]>([]);

interface Props {
  children?: React.ReactNode;
}

export const RoutesProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}: Props) => {
  // in the past we've narrowed the query to only get routes belonging to the
  // current feed (or agency) that the user is in. But with cross-agency tagging
  // (see pr here: https://github.com/postlight/mta-live-media-manager/pull/2414)
  // that assumption is no longer valid as every feed can now use route information
  // from any *other* feed. The usage of `useRoutes` is now redundant. To preserve
  // backward compatibility, all references to the hook have been updated to use `useRoutesByFeed`.
  // In most cases, the feedId parameter would come from `useFeedId` hook. For the parts
  // involving cross-agency tagging (like mention suggestions), the `feedId` will come
  // from the inidividual mentions themselves.
  const { data } = useQuery<RoutesByFeed, RoutesByFeedVariables>(
    RoutesByFeedQuery,
    {
      variables: {
        feedIds: [
          FeedId.NYCTSubway,
          FeedId.LIRR,
          FeedId.MNR,
          FeedId.NYCTBus,
          FeedId.BT,
          ...(REACT_APP_DEPLOY_ENV === 'uat'
            ? [FeedId.OutfrontDEV, FeedId.OutfrontQA]
            : []),
        ],
      },
    },
  );
  const routes = sortedRoutes((data?.routes?.nodes as Route[]) || []);

  return (
    <FeedIdContext.Provider value={routes}>{children}</FeedIdContext.Provider>
  );
};

export const useRoutes = () => useContext(FeedIdContext);

export const useRoutesById = (routes: Route['gtfsId'][]) => {
  const routeData = useContext(FeedIdContext);

  return routeData.filter((route) => routes.indexOf(route.gtfsId) > -1);
};

export const useRoutesByFeedId = (feedId: FeedId) => {
  const routes = useRoutes();

  if (isOutfront(feedId)) {
    return routes.filter((route) => route.feedId === FeedId.NYCTSubway);
  }

  return routes.filter((route) => route.feedId === feedId);
};
