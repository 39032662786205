/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import BaseSearchInput, {
  ISearchInputProps,
} from '../../common/form-elements/search-input';
import { ThemeType } from '../../../theme';

export const PageContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <section
    css={css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `}
    {...props}
  />
);

export const BodyContainer: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      margin: ${theme.spacing.large};
      margin-bottom: -20px;
      max-width: 1000px;
    `}
    {...props}
  />
);

export const BodyRow: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => (
  <div
    css={css`
      display: grid;
      grid-template-rows: auto 1fr 1fr 1fr;
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        'search search search search start end'
        'line   line   status status clear clear'
        'toggle . . . . .';
      grid-column-gap: 12px;
      grid-row-gap: 12px;
    `}
    {...props}
  />
);

export const BodyColumn: React.FC<unknown & { children?: React.ReactNode }> = (
  props,
) => <div {...props} />;

export const BodyRowTitle: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <BodyColumn
    css={css`
      margin-right: 20px;
      width: 85px;
    `}
    {...props}
  />
);

export const RowTitleText: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <span
    css={(theme: ThemeType) => css`
      ${theme.typography.sizes.medium};
      color: ${theme.colors.dark2};
      font-family: ${theme.typography.families.primary};
      font-weight: ${theme.typography.weights.bold};
      text-transform: uppercase;
    `}
    {...props}
  />
);

export const SearchInput: React.FC<ISearchInputProps> = (props) => (
  <div
    css={css`
      grid-area: search;
      align-self: flex-end;
    `}
  >
    <BaseSearchInput {...props} />
  </div>
);
