/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import ms from 'ms.macro';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import AppLink, { ApplinkStyles } from 'components/common/app-link';

import {
  FeedId,
  MESSAGE_TYPE_DESCRIPTIONS,
} from '@mta-live-media-manager/shared';
import Button from 'components/common/Button';
import { useRoutesByFeedId } from 'contexts/Routes';
import { RouteMention } from 'types';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as TRoutes } from 'generated/RoutesByFeed';
import RouteSelector, {
  RouteSelectorProps,
} from 'components/common/form-elements/route-selector';
import { getClearedMessageTypeDescriptionsByFeed } from 'utils/message-type-display';
import { TStatusPillTypes } from 'components/common/status-pill';

import Toggle from 'components/common/form-elements/Toggle';
import BusRouteSelector from 'components/common/form-elements/route-selector/bus-route-selector';
import { useFeedId } from '../../../contexts/FeedId';
import { MessageType } from '../../../generated/global-types';
import {
  ClearedMessages as ClearedMessagesType,
  ClearedMessagesVariables,
} from '../../../generated/ClearedMessages';
import PageMeta from '../../common/PageMeta';
import PageHeading, { ButtonList } from '../../scaffolding/PageHeading';

import { container } from '../../common/styles';
import ClearedMessage from './ClearedMessage';
import Pagination from '../../common/Pagination';
import useQueryParams, { useQueryParam } from '../../../hooks/useQueryParams';
import Loader from '../../common/skeletons/Table';
import Select from '../../common/form-elements/Select';

import { handleUseQueryError, onError } from '../../../utils/error-handler';
import { routeToRouteMention } from '../../../utils/route-mentions';
import * as S from './index.styled';
import Datepicker from 'components/common/form-elements/datepicker';
import theme from 'theme';
import { endOfDay, format, startOfDay, subMonths } from 'date-fns';
import { getRouteType } from 'utils/feed-switches';

const QUERY_PARAM_KEYS = {
  search: 'search',
  routes: 'routes',
  category: 'category',
  all_routes: 'all_routes',
  from_date: 'from_date',
  to_date: 'to_date',
};

const ClearedMessagesQuery = loader('../../../graphql/ClearedMessages.gql');

const MESSAGES_PER_PAGE = 50;

const ClearedMessages: React.FC = () => {
  const [now] = useState(new Date());
  const feedId = useFeedId();
  const queryParams = useQueryParams();
  const history = useHistory();
  const allRoutes = useRoutesByFeedId(feedId);
  const [toDate, updateToDate] = useQueryParam(
    'to_date',
    format(now, 'MM/dd/yyyy'),
  );
  const [fromDate, updateFromDate] = useQueryParam(
    'from_date',
    format(startOfDay(subMonths(now, 6)), 'MM/dd/yyyy'),
  );
  const [revealAll, toggleRevealAll] = useState(false);
  const [rawSearch, setSearch] = useState(
    queryParams.get(QUERY_PARAM_KEYS.search) || '',
  );
  const routes: string[] = decodeURIComponent(
    queryParams.get(QUERY_PARAM_KEYS.routes) || '',
  )
    .split(',')
    .filter(Boolean);

  const updateQueryParam = (name: string, value: string) => {
    queryParams.set(name, encodeURIComponent(value));
    history.push(`/${feedId}/alerts/cleared?${queryParams.toString()}`);
  };
  const routeMentions: RouteMention[] = (
    routes
      .map((gtfsId: string) => allRoutes.find((r) => r.gtfsId === gtfsId))
      .filter(Boolean) as TRoutes[]
  ).map((r) => routeToRouteMention({ route: r }));
  const [search] = useDebounce(rawSearch, ms('1000ms'));

  const [childErrors, setChildErrors] = useState<{ [key: number]: boolean }>(
    {},
  );

  const messageTypes: MessageType[] = [];

  Object.entries(MESSAGE_TYPE_DESCRIPTIONS).forEach(([key, value]) => {
    if (value.toLowerCase().includes(search.toLowerCase())) {
      messageTypes.push(key as MessageType);
    }
  });

  const page = Number(queryParams.get('page') || 1);
  const category = queryParams.get('category') || '';
  const { error, data, loading } = useQuery<
    ClearedMessagesType,
    ClearedMessagesVariables
  >(ClearedMessagesQuery, {
    fetchPolicy: 'network-only',
    variables: {
      feedId,
      allRoutes: !!queryParams.get('all_routes'),
      offset: (page - 1) * MESSAGES_PER_PAGE,
      routeIds: routes.length ? routes : null,
      perPage: MESSAGES_PER_PAGE,
      search: search.length ? search : null,
      messageType: (category as MessageType) || null,
      fromDate: fromDate ? startOfDay(new Date(fromDate)).toISOString() : null,
      toDate: toDate ? endOfDay(new Date(toDate)).toISOString() : null,
    },
    onError,
  });
  handleUseQueryError(error, data, false);

  const messages = data?.searchClearedMessages?.results || [];
  const totalCount = data?.searchClearedMessages?.totalCount || 0;

  const handleClick = (newPage: number) => {
    updateQueryParam('page', newPage.toString());
    window.scrollTo(0, 0);
  };

  const updateSearch = (newSearch: string) => {
    setSearch(newSearch);
    queryParams.set(QUERY_PARAM_KEYS.search, newSearch);
    history.replace(`/${feedId}/alerts/cleared?${queryParams.toString()}`);
  };

  const handleRouteChange = (selRoutes: RouteMention[]) => {
    updateQueryParam(
      QUERY_PARAM_KEYS.routes,
      selRoutes.map((r) => r.routeId).toString(),
    );
  };
  const clearFilters = () => {
    updateQueryParam(QUERY_PARAM_KEYS.category, '');
    updateSearch('');
    updateQueryParam(QUERY_PARAM_KEYS.routes, '');
    updateQueryParam(QUERY_PARAM_KEYS.all_routes, '');
  };
  const CATEGORY_OPTIONS = getClearedMessageTypeDescriptionsByFeed(feedId);
  const disableClearBtn = !(search.length || category.length || routes.length);

  const routeSelectorProps: RouteSelectorProps = {
    isMulti: true,
    routes: routeMentions,
    includeAllRoutes: !!queryParams.get('all_routes'),
    onRadioChange: () => {
      if (queryParams.get('all_routes')) {
        updateQueryParam('all_routes', '');
      } else {
        updateQueryParam('all_routes', 'true');
      }
    },
    placeholder: getRouteType(feedId),
    onChange: handleRouteChange,
  };

  return (
    <div css={container}>
      <PageMeta title="Cleared Alerts" />
      <PageHeading
        breadcrumbs={[
          'Messages',
          {
            label: 'Alerts',
            to: `/${feedId}`,
          },
        ]}
        title="Cleared"
      >
        <ButtonList>
          <AppLink
            to={`/${feedId}/alerts/cleared/history`}
            styleVariations={[
              ApplinkStyles.SECONDARY_BUTTON,
              ApplinkStyles.LARGE_BUTTON,
            ]}
          >
            View All Logs
          </AppLink>
          <AppLink
            to={`/${feedId}/compose`}
            styleVariations={[
              ApplinkStyles.PRIMARY_BUTTON,
              ApplinkStyles.LARGE_BUTTON,
            ]}
            containerStyles={css`
              margin-left: 12px;
            `}
          >
            Compose
          </AppLink>
        </ButtonList>
      </PageHeading>
      <S.BodyContainer>
        <React.Fragment>
          <S.BodyRow>
            <S.SearchInput
              type="text"
              placeholder="Search line, alert type or message"
              value={rawSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSearch(e.target.value);
              }}
              onClear={() => updateSearch('')}
            />
            <Datepicker
              separateLabel
              label="Start"
              css={css`
                grid-area: start;
                align-self: flex-end;
              `}
              inputCss={css`
                box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
                border: 1px solid ${theme.colors['border-dark']};
                margin: 0;
              `}
              popperPlacement="top"
              placeholderText="End Date"
              selected={fromDate ? new Date(fromDate) : null}
              onChange={(date) => {
                const fromDate = format(date, 'MM/dd/yyyy');
                updateQueryParam('from_date', fromDate);
                updateFromDate(fromDate);
              }}
            />
            <Datepicker
              separateLabel
              label="End"
              css={css`
                grid-area: end;
                align-self: flex-end;
              `}
              inputCss={css`
                box-shadow: inset 0 0 4px ${theme.colors['border-dark']};
                border: 1px solid ${theme.colors['border-dark']};
                margin: 0;
              `}
              popperPlacement="top"
              placeholderText="End Date"
              selected={toDate ? new Date(toDate) : null}
              onChange={(date) => {
                const toDate = format(date, 'MM/dd/yyyy');
                updateQueryParam('to_date', toDate);
                updateToDate(toDate);
              }}
            />
            {feedId === FeedId.NYCTBus ? (
              <BusRouteSelector
                {...routeSelectorProps}
                css={css`
                  grid-area: line;
                `}
              />
            ) : (
              <RouteSelector
                {...routeSelectorProps}
                css={css`
                  grid-area: line;
                `}
              />
            )}
            <Select
              css={css`
                grid-area: status;
              `}
              placeholder="Alert Status"
              isSearchable={false}
              options={CATEGORY_OPTIONS}
              value={
                CATEGORY_OPTIONS.find((opt) => opt.value === category) || ''
              }
              onChange={(update: any) => {
                if (update) {
                  const { value }: { value: TStatusPillTypes } = update;
                  updateQueryParam(QUERY_PARAM_KEYS.category, value);
                } else {
                  updateQueryParam(QUERY_PARAM_KEYS.category, '');
                }
              }}
            />
            <Button
              onClick={clearFilters}
              disabled={disableClearBtn}
              css={css`
                height: 44px;
                grid-area: clear;
              `}
            >
              CLEAR
            </Button>
            <div
              css={css`
                grid-area: toggle;
                grid-column: span 2;
                position: relative;
                display: inline-flex;
                align-items: center;
              `}
            >
              <Toggle
                id="toggle-cleared"
                checked={revealAll}
                onChange={() => {
                  toggleRevealAll(!revealAll);
                }}
                css={css`
                  :is(input) {
                    display: flex;
                    justify-content: center;
                    margin-right: 8px;
                    width: 47px;
                    height: 23px;
                  }
                  :is(div) {
                    width: 17px;
                    height: 17px;
                    left: 3px;
                    top: 14px;
                    transform: ${revealAll ? 'translate(24px)' : ''};
                    z-index: 1;
                  }
                  svg {
                    width: 10px;
                    height: 10px;
                  }
                `}
              />
              <p
                css={css`
                  font-weight: 800;
                  font-size: 14px;
                  line-height: 16px;
                `}
              >
                {revealAll ? 'HIDE INCIDENT DETAILS' : 'SHOW INCIDENT DETAILS'}
              </p>
            </div>
          </S.BodyRow>
          <Loader loading={loading}>
            <section>
              <ul
                css={css`
                  padding: 0;
                  border-radius: 8px;
                  max-width: 1000px;
                  box-shadow:
                    0 1px 1px 0 rgba(0, 0, 0, 0.14),
                    0 1px 3px 0 rgba(0, 0, 0, 0.2);
                `}
              >
                {messages.map(
                  (
                    {
                      incidentStartAt,
                      incidentEndAt,
                      notifiedAt,
                      notes,
                      createdAt,
                      startAt,
                      endAt,
                      entitySelectors,
                      totalCount,
                      body,
                      eventId,
                      feedId,
                      id,
                      impacts,
                      author,
                      clearedByUser,
                      neededBy,
                      messageText,
                      taggedTrips,
                      tweetsStatus,
                      emailSmsStatus,
                      webStatus,
                      screensStatus,
                      __typename,
                    },
                    idx: number,
                  ) => (
                    <ClearedMessage
                      __typename={__typename}
                      neededBy={neededBy}
                      key={id || idx}
                      incidentStartAt={incidentStartAt}
                      incidentEndAt={incidentEndAt}
                      notifiedAt={notifiedAt}
                      createdAt={createdAt}
                      startAt={startAt}
                      endAt={endAt}
                      entitySelectors={entitySelectors}
                      totalCount={totalCount}
                      body={body}
                      notes={notes}
                      eventId={eventId}
                      feedId={feedId}
                      id={id}
                      tweetsStatus={tweetsStatus}
                      emailSmsStatus={emailSmsStatus}
                      webStatus={webStatus}
                      screensStatus={screensStatus}
                      impacts={impacts}
                      author={author}
                      clearedByUser={clearedByUser}
                      messageText={messageText}
                      taggedTrips={taggedTrips}
                      revealAll={revealAll}
                      handleError={(hasError) => {
                        // TODO: Fix these type castings to not use `any`
                        const error = childErrors[id as any];
                        if (hasError && !error) {
                          setChildErrors({ ...childErrors, [id as any]: true });
                        } else if (!hasError && error) {
                          const { [id as any]: _, ...newChildErrors } =
                            childErrors;
                          setChildErrors(newChildErrors);
                        }
                      }}
                    />
                  ),
                )}
              </ul>
              <div
                css={css`
                  text-align: right;
                `}
              >
                <Pagination
                  currentPage={page}
                  itemsPerPage={MESSAGES_PER_PAGE}
                  totalItems={totalCount}
                  onPrevClick={() => handleClick(page - 1)}
                  onNextClick={() => handleClick(page + 1)}
                />
              </div>
            </section>
          </Loader>
        </React.Fragment>
      </S.BodyContainer>
    </div>
  );
};

export default ClearedMessages;
