/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ThemeType, ThemeSizes } from '../../../theme';

export const SingleTimeContainerHorizontal: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      font-family: ${theme.typography.families.primary};
      display: flex;
      align-items: center;
      white-space: pre-wrap;
    `}
    {...props}
  />
);
export const SingleTimeContainerVertical: React.FC<
  unknown & { children?: React.ReactNode }
> = (props) => (
  <div
    css={(theme: ThemeType) => css`
      font-family: ${theme.typography.families.primary};
      margin-bottom: 24px;
    `}
    {...props}
  />
);

export const PublishTime: React.FC<
  {
    dateTime: string;
    size?: ThemeSizes;
    labelPos?: 'vertical' | 'horizontal';
    title: string;
  } & { children?: React.ReactNode }
> = ({ children, dateTime, size = 'medium', labelPos, title }) => (
  <time
    css={(theme: ThemeType) => [
      theme.typography.sizes[size],
      css`
        ${labelPos === 'vertical' ? 'padding-top: 4px;' : ''}
        display: block;
        font-family: ${theme.typography.families.primary};
        color: ${theme.colors.black};
      `,
    ]}
    dateTime={dateTime}
    title={title}
  >
    {children}
  </time>
);

export const TimeNotSet: React.FC<{
  size?: ThemeSizes;
  children?: React.ReactNode;
}> = ({ children, size = 'medium' }) => (
  <div
    css={(theme: ThemeType) => [
      theme.typography.sizes[size],
      css`
        color: ${theme.colors.black};
        font-family: ${theme.typography.families.primary};
      `,
    ]}
  >
    {children}
  </div>
);
